import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import api from "../../api";
import {
  Popover,
  message,
  Modal,
  Form,
  Input,
  Typography,
  Divider,
} from "antd";
import SubCard from "../SaasLayout/ProductSubscription/SubCard";
import img2 from "../../assets/noProject2.png";
import useUrlState from "@ahooksjs/use-url-state";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useGetState } from "ahooks";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getUrlSearch,
  removeUrlSearch,
  setSuperGroup,
  setToken,
  setUserStatus,
} from "../../utils/cookie";
import wendaIng from "../../assets/wenda.png";
import { initUrl } from "../../utils";
const { Paragraph } = Typography;
const { TextArea } = Input;

export default function GuidePage() {
  const [licenseList, setLicenseList, getLicenseList] = useGetState([])
  const [
    personalSubsPlanList,
    setPersonalSubsPlanList,
    getPersonalSubsPlanList,
  ] = useGetState([])

  const [state, setState] = useUrlState({})
  const [project, setProject] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [projectList, setProjectList] = useState([])
  const [choiceProject, setChoiceProject] = useState(null)
  const [choicePlanName, setChoicePlanName] = useState(null)
  const [loading, setLoading] = useState(false)
  const [upLoading, setUpLoading] = useState(false)
  const [wXImg, setWXImg] = useState(null)
  const [form] = Form.useForm()

  const userInfo = useSelector((state) => state.login.userInfo)
  const history = useHistory()

  //获取当前企业的订阅方案
  const getSubsPlan = () => {
    api.getSubsPlan().then((res) => {
      console.log('getSubsPlan', res)
      if (res.data?.code === 0) {
        setLicenseList(res.data.data || [])
        if ((res.data?.data || [])?.length === 0) {
          if (state.project) {
            message.warning('您还未订阅企业版产品，欢迎您体验或订阅。')
          }
        } else {
          //记录从哪个企业跳过来的,如果有此项目的订阅信息,跳到对应项目首页
          // const item = res.data?.data?.find(
          //   (_) => _?.prd_code === state.project
          // )
          // if (item) {
          //   window.location.href = item?.product_url
          // }
        }
      } else {
        message.error(res.data.msg)
        setLicenseList([])
      }
    })
  }

  //获取个人版的订阅方案
  const getPersonalSubsPlan = () => {
    api.getPersonalSubsPlan().then((res) => {
      console.log('getPersonalSubsPlan', res)
      if (res.data?.code === 0) {
        setPersonalSubsPlanList(res.data.data || [])
      } else {
        message.error(res.data.msg)
        setPersonalSubsPlanList([])
      }
    })
  }

  const getProjectListFunc = () => {
    setLoading(true)
    api.getRecommend().then((res) => {
      console.log(res)
      if (res.data.code === 0) {
        const list = Object.keys(res.data?.data || {})
          .map((_) => {
            return {
              key: _,
              ...(res.data?.data[_] || {}),
            }
          })
          ?.sort((a, b) => a.seq - b.seq)
        setProjectList(list)
        let item = list?.at(0)
        if (state.project) {
          let _item = list.find((_) => {
            if (_.key === state.project) {
              return true;
            }
            return _.key?.includes(state.project);
          });
          if (_item) {
            item = _item
          }
        }
        setChoiceProject(item)
        if (state.jump) {
          setTimeout(() => {
            const targetElement = document.querySelector('#tj')
            targetElement.scrollIntoView({ behavior: 'smooth' })
          }, 500)
        }
      } else {
        message.error(res.data.msg)
      }
      setLoading(false)
    })
  }

  //获取二维码
  const getQrFunc = () => {
    api.getQRCodeUrl('wx', '3').then((res) => {
      setWXImg(res.data?.data?.qrcode_url)
    })
  }

  useEffect(() => {
    //记录从哪个企业跳过来的,如果有此项目的订阅信息,跳到对应项目首页
    const project = state.project;
    //判断是否是某个产品跳转但是又跳转到登录界面后没记录
    if (getUrlSearch()) {
      if (!state.project) {
        let str = getUrlSearch();
        let searchObj = {};
        str?.split("&")?.forEach((_) => {
          let [key, value] = _?.split("=");
          searchObj[key] = value;
        });
        if (searchObj?.project) {
          setState({
            project: searchObj?.project,
          });
        }
      }
      removeUrlSearch();
    }
    setProject(project);
    getQrFunc();
    setTimeout(() => {
      getSubsPlan()
      getProjectListFunc()
    }, 100)
  }, [])

  useEffect(() => {
    if (JSON.stringify(userInfo) === '{}') {
      return
    }
    if (
      userInfo?.co_type_code === 'FORMAL_CORP' &&
      !(userInfo?.is_admin || userInfo?.is_creator)
    ) {
      let path = '/saas/noProject'
      if (state.project) {
        path += `?project=${state.project}`
      }
      history.push(path)
    }
    //不是个人版 要获取下个人版订阅方案
    if (userInfo?.co_type_code !== 'INDIV_ORG') {
      getPersonalSubsPlan()
    }
  }, [userInfo])

  const changeProject = (item) => {
    setChoiceProject(item)
  }

  const initDom = (list, key) => {
    const item = getLicenseList()?.find((_) => _.prd_code === key) || {}
    const _item = list?.find((_) => _.subs_plan_id === item?.subs_plan_id) || {}
    //个人版本
    if (userInfo?.co_type_code === 'INDIV_ORG') {
      //已订阅个人标准版 隐藏个人试用版
      if (_item.version_type_code === 'PERSONAL_EDITION') {
        list = list?.filter(
          (_) => _.version_type_code !== 'PERSONAL_TRIAL_EDITION'
        )
      }
    } else {
      //已订阅企业标准版、企业高级版、企业定制版 隐藏企业试用版
      if (
        [
          'ENTERPRISE_EDITION',
          'ENTERPRISE_EDITION',
          'ENTERPRISE_CTO_EDITION',
        ]?.includes(_item.version_type_code)
      ) {
        list = list?.filter(
          (_) => _.version_type_code !== 'ENTERPRISE_TRIAL_EDITION'
        )
      }
      //如果订阅过个人版本
      if (getPersonalSubsPlanList()?.length > 0) {
        const personalItem =
          getPersonalSubsPlanList()?.find((_) => _.prd_code === key) || {}
        const _personalItem =
          list?.find((_) => _.subs_plan_id === personalItem?.subs_plan_id) || {}
        //已订阅个人标准版 隐藏个人试用版
        if (_personalItem.version_type_code === 'PERSONAL_EDITION') {
          list = list?.filter(
            (_) => _.version_type_code !== 'PERSONAL_TRIAL_EDITION'
          )
        }
        list = list?.map((_) => {
          if (_.subs_plan_id === _personalItem?.subs_plan_id) {
            _.isSubscription = true
          }
          return _
        })
      }
    }
    list = list?.map((_) => {
      _.arrStr = JSON.stringify(
        (_?.subs_plan_item || [])?.map((__) => ({
          subs_plan_item_type_code: __.subs_plan_item_type_code,
          subs_plan_item_name: __.subs_plan_item_name,
        }))
      );
      return _;
    });
    let KeyObj = {};
    list?.forEach((__) => {
      if (KeyObj[__?.arrStr]) {
        KeyObj[__?.arrStr].push(__);
      } else {
        KeyObj[__?.arrStr] = [__];
      }
    });
    let len = 0;
    Object.keys(KeyObj)?.forEach((key, index) => {
      len = len + 1 + KeyObj[key]?.length;
    });
    const width = `calc(${parseInt(100 / len)}% - 6px)`;
    return (
      <>
        {Object.keys(KeyObj)?.map((key, __index) => {
          return (
            <>
              {__index ? <Divider type="vertical" /> : null}
              <div className="proTableItemBox" style={{ width: width }}>
                <div className="proTableItemTitleBox">
                  <span className="title">功能权益</span>
                </div>
                <div
                  className="proTableItemListBox"
                  style={{ background: "#F9F9F9", color: "#535353" }}
                >
                  {(JSON.parse(key) || [])?.map((item) => (
                    <div>
                      {item.subs_plan_item_type_code === "AUTH_DEADLINE_TIME"
                        ? "授权时间"
                        : item.subs_plan_item_name}
                    </div>
                  ))}
                </div>
              </div>
              {KeyObj[key]?.map((item, index) => (
                <div
                  key={index}
                  className={
                    item.version_type_code?.includes("PERSONAL")
                      ? "proTableItemBox proTableItemBoxFree"
                      : "proTableItemBox"
                  }
                  style={{ width: width }}
                >
                  <div
                    className={
                      item.charge_mode_code === "FREE"
                        ? "proTableItemTitleBox proTableFreeItemTitleBox"
                        : "proTableItemTitleBox"
                    }
                  >
                    {item.charge_mode_code === "FREE" && (
                      <span className="freeSpan">免费</span>
                    )}
                    <div className="proTableItemName">
                      <Paragraph
                        ellipsis={{
                          rows: 1,
                          tooltip: item.subs_plan_name,
                        }}
                      >
                        {item.subs_plan_name}
                      </Paragraph>
                    </div>
                    <div className="proTableItemMsg">
                      <Paragraph
                        ellipsis={{
                          rows: 2,
                          tooltip: item.subs_plan_desc,
                        }}
                      >
                        {item.subs_plan_desc}
                      </Paragraph>
                    </div>
                    <div className="proTableItemBtnBox">
                      <span>
                        <span>¥</span>
                        {item.subs_method_code === "FREE_SUBS" ? "0" : "?"}
                      </span>
                      {getLicenseList()?.some(
                        (_) => _.subs_plan_id === item.subs_plan_id
                      ) || item.isSubscription ? (
                        <div className="successBtn">
                          <CheckCircleFilled /> 已订阅
                        </div>
                      ) : item.subs_method_code === "FREE_SUBS" && upLoading ? (
                        <>
                          <LoadingOutlined
                            style={{
                              color: "#FEC054",
                              fontSize: "20px",
                              fontWeight: "bolder",
                            }}
                          />
                        </>
                      ) : (
                        <div
                          className={
                            item.subs_method_code === "FREE_SUBS"
                              ? "freeBtn"
                              : "contactBtn"
                          }
                          onClick={() => {
                            upFunc(item);
                          }}
                        >
                          {item.subs_method_code === "FREE_SUBS"
                            ? "免费订阅"
                            : "联系商务"}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="proTableItemListBox">
                    {(item?.subs_plan_item || [])?.map((_item) => {
                      if (
                        _item.subs_plan_item_type_code === "AUTH_DEADLINE_TIME"
                      ) {
                        return (
                          <div>
                            {item.subs_default_dur + item.subs_dur_unit}
                          </div>
                        );
                      }
                      if (
                        _item?.is_bool !== null &&
                        _item?.is_bool !== undefined
                      ) {
                        return (
                          <div>
                            {_item.is_bool ? (
                              <CheckCircleFilled />
                            ) : (
                              <CloseCircleOutlined />
                            )}
                          </div>
                        );
                      }
                      let str = _item.item_auth_value?.toString();
                      if (item?.subs_plan_name?.includes("定制")) {
                        return (
                          <div>
                            {str === "-1"
                              ? "不限"
                              : str === ""
                              ? "不涉及"
                              : "定制"}
                          </div>
                        );
                      }
                      return (
                        <div>
                          {str === "-1" ? "不限" : str === "" ? "不涉及" : str}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </>
          );
        })}
        {/* <div className="proTableItemBox" style={{ width: width }}>
          <div className="proTableItemTitleBox">
            <span className="title">功能权益</span>
          </div>
          <div
            className="proTableItemListBox"
            style={{ background: "#F9F9F9", color: "#535353" }}
          >
            {(list?.at(0)?.subs_plan_item || [])?.map((item) => (
              <div>
                {item.subs_plan_item_type_code === "AUTH_DEADLINE_TIME"
                  ? "授权时间"
                  : item.subs_plan_item_name}
              </div>
            ))}
          </div>
        </div>
        {list?.map((item, index) => (
          <div
            key={index}
            className={
              item.version_type_code?.includes("PERSONAL")
                ? "proTableItemBox proTableItemBoxFree"
                : "proTableItemBox"
            }
            style={{ width: width }}
          >
            <div
              className={
                item.charge_mode_code === "FREE"
                  ? "proTableItemTitleBox proTableFreeItemTitleBox"
                  : "proTableItemTitleBox"
              }
            >
              {item.charge_mode_code === "FREE" && (
                <span className="freeSpan">免费</span>
              )}
              <div className="proTableItemName">
                <Paragraph
                  ellipsis={{
                    rows: 1,
                    tooltip: item.subs_plan_name,
                  }}
                >
                  {item.subs_plan_name}
                </Paragraph>
              </div>
              <div className="proTableItemMsg">
                <Paragraph
                  ellipsis={{
                    rows: 2,
                    tooltip: item.subs_plan_des,
                  }}
                >
                  {item.subs_plan_des}
                </Paragraph>
              </div>
              <div className="proTableItemBtnBox">
                <span>
                  <span>¥</span>
                  {item.subs_method_code === "FREE_SUBS" ? "0" : "?"}
                </span>
                {getLicenseList()?.some(
                  (_) => _.subs_plan_id === item.subs_plan_id
                ) || item.isSubscription ? (
                  <div className="successBtn">
                    <CheckCircleFilled /> 已订阅
                  </div>
                ) : item.subs_method_code === "FREE_SUBS" && upLoading ? (
                  <>
                    <LoadingOutlined
                      style={{
                        color: "#FEC054",
                        fontSize: "20px",
                        fontWeight: "bolder",
                      }}
                    />
                  </>
                ) : (
                  <div
                    className={
                      item.subs_method_code === "FREE_SUBS"
                        ? "freeBtn"
                        : "contactBtn"
                    }
                    onClick={() => {
                      upFunc(item);
                    }}
                  >
                    {item.subs_method_code === "FREE_SUBS"
                      ? "免费订阅"
                      : "联系商务"}
                  </div>
                )}
              </div>
            </div>
            <div className="proTableItemListBox">
              {(item?.subs_plan_item || [])?.map((_item) => {
                if (_item.subs_plan_item_type_code === "AUTH_DEADLINE_TIME") {
                  return (
                    <div>{item.subs_default_dur + item.subs_dur_unit}</div>
                  );
                }
                if (_item?.is_bool !== null && _item?.is_bool !== undefined) {
                  return (
                    <div>
                      {_item.is_bool ? (
                        <CheckCircleFilled />
                      ) : (
                        <CloseCircleOutlined />
                      )}
                    </div>
                  );
                }
                return (
                  <div>
                    {_item.item_auth_value?.toString() === "-1"
                      ? "不限"
                      : _item.item_auth_value?.toString()}
                  </div>
                );
              })}
            </div>
          </div>
        ))} */}
      </>
    )
  }

  const initHeaderDom = () => {
    let projectObj = {};
    projectList.forEach((_) => {
      if (projectObj[_?.sale_prd_code]) {
        projectObj[_?.sale_prd_code].list?.push(_);
      } else {
        projectObj[_?.sale_prd_code] = {
          logo_url: _.logo_url,
          sale_prd_name: _.sale_prd_name,
          sale_prd_code: _.sale_prd_code,
          list: [_],
          seq: _.seq,
        };
      }
    });
    const keyList = Object.keys(projectObj)?.sort(
      (a, b) => projectObj[a]?.seq - projectObj[b]?.seq
    );
    return (
      <div className="proListBoxContent">
        {keyList?.map((key) => {
          let len = projectObj[key].list?.length;
          if (len >= 2) {
            let isCheck = projectObj[key].list?.some(
              (_) => _.key === choiceProject?.key
            );
            return (
              <div
                key={key}
                className={
                  isCheck ? "proListItems proListItemsActive" : "proListItems"
                }
                style={{
                  width: `${
                    100 / (keyList?.length || 1) > 33
                      ? 33
                      : 100 / (keyList?.length || 1)
                  }%`,
                }}
                onClick={(e)=>{
                  if(isCheck){
                    return
                  }
                  changeProject(projectObj[key].list?.at(0));
                  e.stopPropagation()
                }}
              >
                <img src={projectObj[key].logo_url} alt="" />
                <div className="saleSolutionNameDiv">
                  {projectObj[key].list?.map((_, index) => (
                    <>
                      {index !== 0 && <div className="line"></div>}
                      <span
                        className={
                          _.key === choiceProject?.key ? "activeSpan" : ""
                        }
                        onClick={(e) => {
                          changeProject(_);
                          e.stopPropagation()
                        }}
                      >
                        {_.sale_solution_name}
                      </span>
                    </>
                  ))}
                </div>
              </div>
            );
          }
          const item = projectObj[key].list?.at(0);
          return (
            <div
              key={key}
              className={
                item.key === choiceProject?.key
                  ? "proListItem proListItemActive"
                  : "proListItem"
              }
              style={{
                width: `${
                  100 / (keyList?.length || 1) > 33
                    ? 33
                    : 100 / (keyList?.length || 1)
                }%`,
              }}
              onClick={() => {
                changeProject(item);
              }}
            >
              <img src={item.logo_url} alt="" />
            </div>
          );
        })}
      </div>
    );
  };

  const upFunc = (item) => {
    if (upLoading) {
      return
    }
    if (item.subs_method_code === 'FREE_SUBS') {
      setUpLoading(true)
      api.setFreeSubscription(item.release_id).then((res) => {
        console.log(res)
        if (res.data?.code === 0) {
          message.success(
            `恭喜您订阅 ${choiceProject?.project_name} ${item?.subs_plan_name} 成功 !`
          )
          setTimeout(() => {
            api.getEnterprise().then((res2) => {
              if (res2?.code === 0 && res2.data?.length >= 1) {
                const co_id = res2.data?.find(
                  (_) => _.co_type_code === 'INDIV_ORG'
                )?.co_id
                api.switchEnterpriseById(co_id).then((res3) => {
                  console.log(res3?.data?.data);
                  if (res3.data.code === 0) {
                    setToken(res3?.data?.data?.token || "");
                    setSuperGroup(res3?.data?.data?.super_group || false);
                    setUserStatus("0");
                    // window.location.href = window.location.origin;
                    setState({
                      jump: undefined,
                    })
                    window.location.reload()
                  } else {
                    message.error(res?.data?.msg || '切换失败')
                  }
                })
              } else {
                message.error(res.msg)
              }
            })
          }, 1000)
        } else {
          message.error(res.data.msg)
        }
        setUpLoading(false)
      })
    } else {
      setIsModalOpen(true)
      setChoicePlanName(item.subs_plan_name)
      let name = userInfo?.co_name || null
      if (userInfo?.co_type_code === 'INDIV_ORG') {
        name = null
      }
      form.setFieldsValue({
        enterpriseName: name,
        name: userInfo?.full_name || null,
        phone: userInfo?.mobile_no || null,
        demand: null,
      })
    }
  }

  const onFinish = () => {
    form.validateFields().then((res) => {
      let obj = {
        project_code: choiceProject?.key,
        subs_plan_name: choicePlanName,
        user_id: userInfo?.individual_id,
      }
      const params = {
        co_name: res.enterpriseName, //企业名称
        full_name: res.name, //姓名
        mobile: res.phone, //手机号
        remark: res.demand, //备注
        extra_info: JSON.stringify(obj),
      }
      api.upContact(params).then((res2) => {
        console.log(res)
        if (res2.data?.code === 0) {
          setIsModalOpen(false)
          message.success('邮件已发送，7天内商务会主动联系您，请耐心等待！')
        } else {
          message.error(res2.data?.msg)
        }
      })
    })
  }

  return (
    <div className={styles['root']}>
      <div className="concent">
        <div className="contentDiv">
          <div className="contentBox">
            {licenseList?.length > 0 ? (
              <>
                <div className="productTitle">已订阅</div>
                <div className="licenseListBox">
                  {licenseList?.map((item, index) => (
                    <SubCard
                      max={6}
                      deleteCallBack={getSubsPlan}
                      // deleteIcon={true}
                      // noBtn={true}
                      btnCallBack={(info) => {
                        window.open(info?.product_url, '_new')
                      }}
                      btnText="开始体验"
                      style={{ height: 'calc(100% - 20px)' }}
                      key={index}
                      info={item}
                    ></SubCard>
                  ))}
                </div>
              </>
            ) : (
              <div className="userMsgBox">
                <img src={img2} alt="" />
                <div className="userMsg">
                  <div className="userMsgTitle">
                    您现在可以开始免费体验或订阅我们的产品服务啦!
                  </div>
                  <div className="userMsgText">
                    如果您想加入同事已经创建的企业或组织
                    <Popover
                      placement="bottomLeft"
                      content={
                        <div>
                          <div
                            style={{ marginBottom: '10px', fontWeight: 'bold' }}
                          >
                            如何加入企业或组织?
                          </div>
                          <span>
                            您可以联系管理员将邀请二维码或链接发送给您，扫描二维码或者打开链接申请加入。
                          </span>
                        </div>
                      }
                      overlayClassName="myCardPopover"
                    >
                      <span>如何加入</span>
                    </Popover>
                  </div>
                </div>
              </div>
            )}
            <div
              className="productTitle"
              // style={{ marginBottom: "20px" }}
              id="tj"
            >
              为您推荐
            </div>
          </div>
        </div>
        {loading ? null : (
          <>
            <div className="proListBox">{initHeaderDom()}</div>
            <div className="proTableBox">
              <div className="proTable">
                {initDom(choiceProject?.list, choiceProject?.key)}
              </div>
            </div>
          </>
        )}

        <Modal
          visible={isModalOpen}
          centered={true}
          footer={null}
          onCancel={() => {
            setIsModalOpen(false)
          }}
          wrapClassName="commModal"
          width={1000}
        >
          <div className="commModalContent">
            <div className="commModalLeft">
              <div className="titleBox">在线沟通</div>
              <div className="commModalLeftContent">
                <span>企业微信</span>
                <img className="qr" src={wXImg} alt="" />
                <span>在线客服</span>
                <img
                  className="kf"
                  src={wendaIng}
                  alt=""
                  onClick={() => {
                    let href = initUrl(
                      'https://help.touzhiwang.com/service',
                      choiceProject?.project_name,
                      userInfo
                    )
                    window.open(href, 'up')
                  }}
                />
              </div>
              <div className="userIdBox">
                您的用户ID：{userInfo?.individual_id}
              </div>
            </div>
            <div className="commModalRight">
              <div className="titleBox">留下联系方式，由我们联系您</div>
              <div className="commModalRightContent">
                <Form
                  form={form}
                  style={{
                    width: '100%',
                  }}
                  layout="vertical"
                >
                  <Form.Item
                    name="enterpriseName"
                    label="企业或组织名称"
                    rules={[
                      {
                        required: true,
                        message: '请输入企业或组织名称',
                      },
                    ]}
                  >
                    <Input placeholder="请输入企业或组织名称" />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label="姓名"
                    rules={[
                      {
                        required: true,
                        message: '请输入姓名',
                      },
                    ]}
                  >
                    <Input placeholder="请输入姓名" />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label="手机号"
                    rules={[
                      {
                        required: true,
                        message: '请输入手机号',
                      },
                      {
                        pattern:
                          /^(13\d|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18\d|19[0-35-9])\d{8}$/,
                        message: "请输入正确的手机号",
                      },
                    ]}
                  >
                    <Input placeholder="请输入手机号" />
                  </Form.Item>
                  <Form.Item
                    name="demand"
                    label="您希望的试用或订阅的产品及需求"
                  >
                    <TextArea
                      style={{
                        height: 80,
                        resize: 'none',
                      }}
                    />
                  </Form.Item>
                </Form>
                <div className="formBtn" onClick={onFinish}>
                  提交
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}
